// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD31NWU3mUJJFZAgofzahgIhpSVzfH3Q0k",
  authDomain: "test-f41c4.firebaseapp.com",
  projectId: "test-f41c4",
  storageBucket: "test-f41c4.appspot.com",
  messagingSenderId: "874793666291",
  appId: "1:874793666291:web:83a00be617983dd8ac9986",
  measurementId: "G-NYYE3CQ8MN",
  databaseURL:
    "https://test-f41c4-default-rtdb.asia-southeast1.firebasedatabase.app/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase
const auth = getAuth(app);
const db = getDatabase(app);
const analytics = getAnalytics(app);
export { auth, db };
export const COMPANY_NAME = "CA PRO PMS"; // "JVA PRIVATE LIMITED";
